import Error404BodyContent from '../components/Error404/Error404BodyContent'

function Error404(){
    return (
        <div>
            <Error404BodyContent />
        </div>
    )
}

export default Error404