import React from 'react'
import './Error404.css'

function Error404BodyContent(){
    return (
        <div className = "container-full-page">
            <div className = "error-404-section">
                <p className = "super-big-text">404</p>
                <p className = "semi-big-text">Certificate not found</p>
                <p>Please reach out to us at hello@heicodersacademy.com if you require any assistance!</p>
            </div>
        </div>
    )
}

export default Error404BodyContent