import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles/home.module.css";
import Error404 from "./Error404";


const Home = () => {

    const certId = useLocation().pathname.slice(1);
    const certPath = `/assets/certs/${certId}.jpg`;
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        const cert = new Image()
        cert.onerror = () => { setStatus('error'); }
        cert.onload = () => { setStatus('success'); }
        cert.src = `${window.location.origin}${certPath}`
        return () => {
            cert.onerror = null;
        }
    }, [certId])

    return (
        <div className = {styles.__page}>
            {
                (status === "loading") ?
                    <div/>
                :
                (status === "success") ?
                    <React.Fragment>
                        <img src = {certPath} className = {styles.__certificate}/>
                        <Link to = {certPath} target="_blank" download className = {styles.__download}>Download</Link>
                    </React.Fragment>
                :
                    <div style = {{width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Error404 />
                    </div>
            }
        </div>
    )
}

export default Home