import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home"
import React from "react";
import Error404 from "./pages/Error404";


const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path = "/:id" element = {<Home />} />
                <Route path = "/" element = {<Error404 />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
